import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {useHistory, useParams} from "react-router-dom";
import {FollowUpType} from "../FollowUpList/FollowUpList";
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";

type FileType = {
    storage_file_id: "7809",
    name: "tiles-1733411319.jpeg",
    name_to_show: "tiles.jpeg",
    id: "1329"
}

function truncateStringMiddle(str: string, maxLength: number = 30): string {
    if (str.length <= maxLength) {
        return str;
    }

    const halfLength = Math.floor((maxLength - 3) / 2);
    const start = str.slice(0, halfLength);
    const end = str.slice(-halfLength);

    return `${start}...${end}`;
}

export const useFollowUpView = () => {
    const history = useHistory()
    const { id }: { id: string } = useParams()
    const { setLoading, userData } = useContext(MainContextProvider)
    const [item, setItem] = useState<FollowUpType>({} as FollowUpType)
    const [isEditMode, setIsEditMode] = useState(false)
    const isShowPublish = +item?.status === 0
    const [tch, setTch] = useState(true)
    const [file, setFile] = useState<File | null>(null);
    const [files, setFiles] = useState<FileType[]>([])
    const [isOpenDestroyModal, setIsOpenDestroyModal] = useState(false)

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/call-follow-up/${id}`)
            if (status === 200) {
                setItem(data)
                setFiles(data?.file || [])
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        document.title = "Follow Up"
        getData()
    },[])

    const updateDescription = (text: string) => {
        setItem({ ...item, description: text })
        setTch(false)
    }

    const updateItem = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/call-follow-up/${id}`, {
                description: item.description
            })
            if (status === 200) {
                setTch(true)
                setIsEditMode(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    const publishItem = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/call-follow-up/${id}`, {
                status: 1
            })
            if (status === 200) {
                setItem({ ...item, status: 1 })
                setTch(true)
                setIsEditMode(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    const handleSubmit = async (file: File) => {
        const formData = new FormData();
        if (file) {
            formData.append('UploadStorageForm[documents][0]', file);
        }

        setLoading(true)
        try {
            const response = await customAxios.post(`/call-follow-up/${id}/files-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                toastifyError('File upload error')
            }

            toastifySuccess('File successfully uploaded!');
            await getData();
        } catch (err) {
            toastifyError('An error occurred while uploading the file.');
        }
        setLoading(false)
    };

    function ext(name: string) {
        let m = name.match(/\.([^.]+)$/)
        return m && m[1]
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const selectedFile = event.target.files?.[0];
        if (!!selectedFile) {
            // @ts-ignore
            if (!['pdf', 'doc', 'png', 'docx', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'xls', 'xlsx', 'rar', 'zip'].includes(ext(selectedFile.name).toLowerCase())) {
                toastifyError('Allowed only pdf, doc, png, docx, jpg, jpeg, gif, tiff, bmp, xls, xlsx, rar, zip')
                return false
            }

            /*if (!allowedFileTypes.includes(selectedFile.type)) {
                toastifyError('Invalid file format. Allowed formats: PDF, DOC, PNG, DOCX, JPG, JPEG, GIF, TIFF, BMP, XLS, XLSX, RAR, ZIP.');
                setFile(null);
                return;
            }*/

            if (selectedFile.size > 5 * 1024 * 1024) { // Максимальный размер 5MB
                toastifyError('The file is too large. Maximum size is 5MB.');
                setFile(null);
                return;
            }

            handleSubmit(selectedFile)
        }
    };

    const downloadFile = async (file_id: string, name: string) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${file_id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    const deleteFile = async (file_id: string) => {
        setLoading(true)
        try {
            const { status } = await customAxios.delete(`/call-follow-up/${id}/file/${file_id}`)
            if (status === 200) {
                await getData()
            }
        } catch (e) {}
        setLoading(false)
    }

    const destroyItem = async () => {
        setLoading(true)
        try {
            const { status } = await customAxios.delete(`/call-follow-up/${id}`)
            if (status === 200) {
                history.push(`/follow-ups/`)
            }
        } catch (e) {}
        setLoading(false)
    }

    return {
        item,
        isEditMode,
        setIsEditMode,
        isClient: userData?.status.type_code === 100,
        isShowPublish,
        updateDescription,
        updateItem,
        publishItem,
        tch,
        handleFileChange,
        files,
        truncateStringMiddle,
        downloadFile,
        deleteFile,
        userData,
        id,
        destroyItem,
        isOpenDestroyModal,
        setIsOpenDestroyModal
    }
}