import React from "react";
import "./follow-up-list.scss"
import newCallImage from "../icons/new-call.svg"
import {FollowUpListItem} from "./FollowUpListItem";
import {useFollowUpList} from "./useFollowUpList";
import {CompanyType} from "../../../types/company.type";
import {CustomLoader} from "../../../components/UI/CustomLoader/CustomLoader";
import Pagination from "../../../components/other/Pagination";
import {FollowUpListFilter} from "./FollowUpListFilter";
import {FollowUpModal} from "../FollowUpModal/FollowUpModal";
import {openPopupWidget} from "react-calendly";
import {ViewPageDuration} from "../../../components/other/ViewPageDuration/ViewPageDuration";
import {DeleteFollowUpModalConfirm} from "../DeleteFollowUpModalConfirm/DeleteFollowUpModalConfirm";

export const FollowUpCallTypes: { [key: number]: string } = {
    0: "Onboarding Call",
    1: "Call with VLO",
    2: "Legal Discovery Session",
    3: "Legal Q&A Call"
}

export const followUpCallTypesArray = Object.entries(FollowUpCallTypes).map(([value, label]) => ({
    value, label
}));

export type FollowUpType = {
    company_id: number
    created_at: number
    date: number
    description: string
    id: number
    link: string
    status: number
    type: number
    company: CompanyType
    business_goals: { title: string, id: number }[]
}

export const followUpColumnsWidth = [
    { flexGrow: 1 },
    { width: "140px", flexShrink: 0 },
    { width: "160px", flexShrink: 0 },
    { width: "225px", flexShrink: 0 },
]

export const dateMonthAgo = new Date();
dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);

export const FollowUpList: React.FC = () => {
    const {
        items,
        loading,
        updateParam,
        searchParams,
        count,
        modalConfig,
        setModalConfig,
        getData,
        isClient,
        clientCallLink,
        userData,
        companies,
        isOpenDestroyModal,
        setIsOpenDestroyModal,
        destroyItem
    } = useFollowUpList()

    if (companies.length === 0) return <></>

    return (
        <>
            <div className={"follow-up-list"}>
                {userData?.status.type_code === 100 && <ViewPageDuration name={"view_follow_up_list"} data={{
                    user_id: userData?.user_id,
                    timestamp: (+(new Date()) / 1000).toFixed(),
                    company_id: companies[0]?.id
                }}/>}
                <div className="follow-up-list__content">
                    <h1>Calls & Follow-ups</h1>
                    <h2>Conducted</h2>
                    <FollowUpListFilter isClient={isClient} />
                    {
                        loading && <CustomLoader />
                    }
                    {!loading && items.length > 0 && <div className="follow-up-list__table">
                        <div className="follow-up-list__table-tr">
                            <div style={followUpColumnsWidth[0]} className="follow-up-list__table-th">Project</div>
                            <div style={followUpColumnsWidth[1]} className="follow-up-list__table-th">Type</div>
                            <div style={followUpColumnsWidth[2]} className="follow-up-list__table-th">When</div>
                            <div style={followUpColumnsWidth[3]} className="follow-up-list__table-th">Options</div>
                        </div>
                        {
                            (items || []).map(item => <FollowUpListItem
                                isClient={isClient}
                                key={item.id}
                                item={item}

                                toggleButton={(key) => {
                                    if (key === "edit") {
                                        setModalConfig({ id: item.id, data: item })
                                    } else if (key === "delete") {
                                        setIsOpenDestroyModal(item.id + "")
                                    }
                                }}
                            />)
                        }

                    </div>}
                    {
                        items.length === 0 && !isClient && <p style={{ padding: "50px", textAlign: "center" }}>
                            Nothing to show
                        </p>
                    }
                    {items.length > 10 && <Pagination
                        currentPage={Number(searchParams.get("page")) || 1}
                        pagesAmount={Math.ceil(Number(count) / 10)}
                        setCurrentPage={page => updateParam("page", page + "")}
                    />}
                    {items.length === 0 && isClient && <div className={"follow-up-list__not-found"}>
                        <div className="follow-up-list__not-found-heading">
                            Your Calls and Follow-Ups Will Appear Here
                        </div>
                        <p>
                            After your calls with our team, all key details and follow-up information will be displayed
                            here.
                            <br/><br/>
                            Check back soon to stay updated!
                        </p>
                    </div>}
                </div>
                <div className="follow-up-list__new-call">
                    <img src={newCallImage} alt=""/>
                    {isClient && <h5>Have questions to discuss?</h5>}
                    {isClient && <p>Request a call, and VLO will help schedule it for you!</p>}
                    {!isClient &&
                        <button onClick={() => setModalConfig({})} className={"btn-black w100 text-uppercase"}>
                            ADD new call
                        </button>}
                    {isClient &&
                        <button
                            onClick={() => openPopupWidget({
                                url: `${clientCallLink}${clientCallLink.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}`,
                            })}
                                className={"btn-black w100 text-uppercase"}>
                            REQUEST new call
                        </button>}
                </div>
            </div>
            <FollowUpModal
                isOpen={!!modalConfig}
                id={modalConfig?.id}
                data={modalConfig?.data}
                close={(key) => {
                    setModalConfig(null)
                    if (key) getData()
                }}
            />
            <DeleteFollowUpModalConfirm
                isOpen={!!isOpenDestroyModal}
                close={() => setIsOpenDestroyModal(null)}
                confirm={destroyItem}
            />
        </>
    )
}