import './TaskTabList.scss'
import React, {useContext, useEffect, useRef, useState} from "react";
import clientIcon from '../../../../assets/images/client.png'
import {MainContextProvider} from "../../../../context/MainContext";
import createIcon from "./add_task.svg"
import fileIcon from "./task-file.svg"
import linkIcon from "./task-link.svg"
import deleteIcon from "./cancelIcon.svg"
import notRequiredIcon from "./not-required.svg"
import waitIcon from "./task-wait.svg"
import avatarIcon from '../../../../assets/images/noAvatar.svg'
import whitePenIcon from '../../../../assets/images/pen-white.svg'
import cancelIcon from '../../../../assets/images/pen-white.svg'
import taskEditIcon from '../../../../containers/Client/Offer/ccomponents/TaskDeliverablesForOffer/taskEditIcon.svg'
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {MobileTaskItem} from "../../../../containers/Client/Offer/ccomponents/MobileTaskItem";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import customAxios from "../../../../service/api/interceptors";
import {ModalLoader} from "../../ModalLoader/ModalLoader";
import {useHistory, useLocation} from "react-router-dom";
import {CreditControlModal} from "../../../../containers/Modals/Credits/CreditControlModal";

const statuses = [
    { value: '5', label: 'Awaiting client approval', color: '#000000' },
    { value: '2', label: 'To do', color: '#2361FF' },
    { value: '1', label: 'In progress', color: '#000000' },
    { value: '3', label: 'Done', color: '#07B56C' },
]

const TaskItem: React.FC<any> = ({ haveToBeApprove,credits_per_hour, deleteDeliverableItem, is_no_deliverable, credit_price, is_fee_exempt, partner_request_id, title, price_estimate, price_per_hour, deadline, time_estimate, providers, showStatus, status, project_id, id, deleteTask, isAcceptToDeleteTask, accepted, type, createDeliverable, deliverables }) => {
    const { setModalType, setModalData, userData } = useContext(MainContextProvider)
    const statusItem = statuses.find((el) => el.value + '' === status + '')
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const rules = {
        delete_deliverable: userData?.status.type_code === 300,
        create_deliverable: userData?.status.type_code === 300
    }

    const getTime = () => {
        if(price_per_hour) {
            return '-'
        }

        if(accepted === 5) {
            return dateFormatter(deadline)
        } else {
            return `${time_estimate} days`
        }
    }

    const downloadFile = async (id: string, name: string) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div id={`offer-task-id-${id}`} className={'singleOffer__deliverables-task w100'} style={{ border: '1px solid rgb(202, 213, 221)', position: 'relative' }}>
            {loading && <ModalLoader />}
            <div className="singleOffer__deliverables-task-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', }}>
                <p>{title}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!!Number(is_fee_exempt) && userData?.status.type_code === 300 && <div className={'singleOffer__deliverables-task-title-fee_exempt'}>
                        Fee Exempt
                    </div>}
                    {userData?.status.type_code === 300 && <div ref={dropdownRef} className={'task-deliverables-for-offer__dropdown'}>
                        <img onClick={() => setIsOpen(!isOpen)} src={taskEditIcon} alt=""/>
                        {isOpen && <div className="offerHeader__dropdown-container task-status__body">
                            <div onClick={() => {
                                setModalType('create_legal_task')
                                setModalData({id, project_id, partner_request_id})
                            }} className={'task-status__body-item'}>Edit
                            </div>
                            <div style={{color: 'red'}} onClick={() => deleteTask(id)}
                                 className={'task-status__body-item'}>Delete
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
            <div className="singleOffer__deliverables-task-info">
                <div className="task-tab-list__photo singleOffer__deliverables-task-photos" style={{width: '20%'}}>
                    <div style={{display: "flex"}}>
                        {providers && providers.map((item: any) =>
                            <img
                                onClick={() => {
                                    userData && setModalData({id: item.id, type: item.type})
                                    userData && setModalType('manager_profile_modal')
                                }}
                                style={{
                                    marginLeft: '2px',
                                    width: '27px',
                                    height: '27px',
                                    borderRadius: '10px',
                                    cursor: 'pointer'
                                }}
                                src={item.avatar_path || clientIcon}
                                alt=""
                            />)}
                    </div>
                </div>
                <div className="singleOffer__deliverables-task-status" style={{width: '20%'}}>
                    <div className="singleOffer__deliverables-task-label">Status</div>
                    <div className={'singleOffer__deliverables-task-text  offer-task-status'}
                         style={{color: statusItem?.color}}>{statusItem?.label}</div>
                </div>
                <div className="singleOffer__deliverables-task-status" style={{width: '18%'}}>
                    <div className="singleOffer__deliverables-task-label offer-task-estimate">Estimate</div>
                    <div
                        className={'singleOffer__deliverables-task-text offer-task-estimate'}>{type === 'COVERED_BY_SUBSCRIPTION' ? '-' : getTime()}</div>
                </div>
                <div className="singleOffer__deliverables-task-status" style={{width: '22%'}}>
                    <div className="singleOffer__deliverables-task-label">Price</div>
                    {
                        type === 'COVERED_BY_SUBSCRIPTION' ?
                            <div className={'singleOffer__deliverables-task-text offer-task-price'} style={{color: '#F76800'}}>Covered by
                                Monthly Plan</div> :
                            <>
                                {credit_price === 0 && <div className={'singleOffer__deliverables-task-text offer-task-price'}>{(price_per_hour || credits_per_hour) ? `${price_per_hour ? `$${price_per_hour}/hour` : `${credits_per_hour} credit${+credits_per_hour > 0 && 's'}/hour`}` : price_estimate}</div>}
                                {credit_price > 0 && <div className={'singleOffer__deliverables-task-text offer-task-price'}>{credit_price} Credits</div>}
                            </>

                    }
                </div>
                {type === "PAID_BY_CREDIT" && <div className={'task-tab-list__paid-item'}>
                    Paid
                </div>}
                {/*{userData?.status.type_code === 100 && credit_price > 0 && type !== "PAID_BY_CREDIT" && <div
                    onClick={() => haveToBeApprove({id, title, credit_price})}
                    className={'task-tab-list__pay-item'}>
                    Pay
                </div>}*/}
                {/*<div className="deliverables_tasks__button-with-tooltip flex-center" style={{ width: '20%', justifyContent: 'flex-end' }}>
                    {userData?.status.type_code === 300 && <a style={{ background: '#2361FF' }} onClick={() => {
                        setModalType('create_legal_task')
                        setModalData({ id, project_id, partner_request_id })
                    }} className={'deliverables__desc-edit'}>
                        <img
                            src={whitePenIcon} alt=""
                        />
                    </a>}
                    <div>
                        {userData?.status.type_code === 300 && isAcceptToDeleteTask &&
                            <a style={{ marginLeft: 0 }} onClick={() => deleteTask(id)} className="milestones__delete">✕</a>}
                    </div>
                </div>*/}
            </div>
            {type !== "PAID_BY_CREDIT" && <div className="task-deliverables-for-offer__items">
                {+is_no_deliverable === 1 && (
                    <div className={'task-deliverables-for-offer__item'}>
                        <div className="task-deliverables-for-offer__item-left">
                            <div style={{background: '#E4EAF8'}} className="task-deliverables-for-offer__icon">
                                <img src={notRequiredIcon} alt=""/>
                            </div>
                            <span>This task does not require a deliverable</span>
                        </div>
                    </div>
                )}
                {(userData?.status.type_code === 100 && +is_no_deliverable === 0 && deliverables.length === 0) &&
                    <div className={'task-deliverables-for-offer__item'}>
                        <div className="task-deliverables-for-offer__item-left">
                            <div style={{background: '#FFFFFF'}} className="task-deliverables-for-offer__icon">
                                <img src={waitIcon} alt=""/>
                            </div>
                            <span style={{color: '#81889A'}}>Your deliverable will be displayed here once uploaded by the VLO</span>
                        </div>
                    </div>}
                {
                    deliverables.map((el: { id: string, url: string, file_id: string, name: string, file: null | { id: string, name_to_show: string } }) => {
                        if (el.url) {
                            return (
                                <div key={el.id} className={'task-deliverables-for-offer__item'}>
                                    <div className="task-deliverables-for-offer__item-left">
                                        <div style={{background: '#2361FF'}}
                                             className="task-deliverables-for-offer__icon">
                                            <img src={linkIcon} alt=""/>
                                        </div>
                                        <span>{el.name}</span>
                                    </div>
                                    <div className="task-deliverables-for-offer__item-right">
                                        <div onClick={() => navigator.clipboard.writeText(el.url)}
                                             className="task-deliverables-for-offer__button mr5">
                                            COPY LINK
                                        </div>
                                        {rules.delete_deliverable && <div
                                            onClick={() => deleteDeliverableItem(el.id)}
                                            className="task-deliverables-for-offer__button task-deliverables-for-offer__button-squire">
                                            <img src={deleteIcon} alt=""/>
                                        </div>}
                                    </div>
                                </div>
                            )
                        }

                        if (el.file) {
                            return (
                                <div key={el.id} className={'task-deliverables-for-offer__item'}>
                                    <div className="task-deliverables-for-offer__item-left">
                                        <div style={{background: '#4E7C4D'}}
                                             className="task-deliverables-for-offer__icon">
                                            <img src={fileIcon} alt=""/>
                                        </div>
                                        <span>{el.name}</span>
                                    </div>
                                    <div className="task-deliverables-for-offer__item-right">
                                        <div
                                            onClick={() => downloadFile(String(el.file?.id), String(el.file?.name_to_show))}
                                            className="task-deliverables-for-offer__button mr5">
                                            download
                                        </div>
                                        {rules.delete_deliverable && <div
                                            onClick={() => deleteDeliverableItem(el.id)}
                                            className="task-deliverables-for-offer__button task-deliverables-for-offer__button-squire">
                                            <img src={deleteIcon} alt=""/>
                                        </div>}
                                    </div>
                                </div>
                            )
                        }
                    })
                }

                {(rules.create_deliverable && +is_no_deliverable === 0) &&
                    <div onClick={() => createDeliverable(id, title)}
                         className={'task-deliverables-for-offer__item pointer'}>
                        <div className="task-deliverables-for-offer__item-left">
                            <div className="task-deliverables-for-offer__icon">
                                <img src={createIcon} alt=""/>
                            </div>
                            <span>Add Deliverable</span>
                        </div>
                    </div>}
            </div>}
        </div>
    )
}

const ProviderInfo: React.FC<any> = ({ provider, setModalType, setModalData }) => {
    return (
        <div className={'task-tab-list__provider'}>
            <div className="task-tab-list__provider-left">
                <div>
                    <img className={'task-tab-list__provider-image'} src={provider?.avatar_path || clientIcon} alt=""/>
                </div>
                <div className={'task-tab-list__provider-info'}>
                    <h4>{provider?.fullname}</h4>
                    <em>{provider?.title}</em>
                </div>
            </div>
            <div className="task-tab-list__provider-right">
                <b><strong style={{ fontWeight: 700, fontSize: '18px' }}>${provider?.hourly_pay}</strong></b>
                <a onClick={() => {
                    setModalData({ id: provider.id, type: provider.type })
                    setModalType('manager_profile_modal')
                }}>More info</a>
            </div>
        </div>
    )
}

export const TaskTabList: React.FC<any> = ({ tasks, showStatus, project, deleteTask, isAcceptToDeleteTask, accepted, activeIndex = 0, setActiveIndex, offer_id, updateParent }) => {
    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState<{ id: string, title: string, credit_price: number } | null>(null)
    const { userData, setModalType, setModalData } = useContext(MainContextProvider)
    const [deliverables, setDeliverables] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const location = useLocation()

    const getDeliverables = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/offer/${offer_id}/deliverables`)
            setDeliverables(data || [])
            //history.push(location.pathname)
        } catch (e) {}
    }

    useEffect(() => {
        if (offer_id) getDeliverables().then()
    }, [offer_id, location.search])

    const createDeliverable = (task_id: string, title: string) => {
        setModalType('offer_deliverable_modal')
        setModalData({ task_id, title, offer_id })
    }

    const deleteDeliverableItem = async (id: string) => {
        if (!window.confirm('Are you sure?')) {
            return false
        }

        setLoading(true)
        try {
            const { status, data } = await customAxios.delete(`/company/deliverable/${id}`)
            if (status === 204) {
                await getDeliverables()
            }
        } catch (e) {}
        setLoading(false)
    }

    const setTaskUsPaid = async () => {
        setLoading(true)
        try {
            const { status } = await customAxios.post(`/company/task/${haveToBeApproveModal?.id}/pay-credits`)
            if (status === 200) {
                updateParent()
            }
        } catch (e) {}
        setLoading(false)
    }

    const providerItems = ((tasks || [])[activeIndex]?.tasks)?.filter((el: any) => String(el.providers[0].type) === '200') || []
    const vloItems = ((tasks || [])[activeIndex]?.tasks)?.filter((el: any) => String(el.providers[0].type) === '300') || []

    return (
        <div className={'task-tab-list'} style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            {haveToBeApproveModal && <CreditControlModal
                submit={setTaskUsPaid}
                close={() => setHaveToBeApproveModal(null)}
                creditsSum={haveToBeApproveModal.credit_price}
                commonTitle={haveToBeApproveModal.title}
                noCreditsSubtitle={'noCreditsSubtitle'}
                acceptTitle={`${haveToBeApproveModal.credit_price} credit${haveToBeApproveModal.credit_price > 1 ? 's' : ''} will be charged TO PROCEED WITH THIS TASK.`}
                noCreditsDescription={haveToBeApproveModal.title}
            />}
            {/*{userData?.status.type_code === 100 && tasks.length >= 2 && <p style={{ maxWidth: '90%', color: '#bbb', fontSize: '12px', lineHeight: '15px', textAlign: 'center', margin: '-30px auto 50px auto' }}>
                We’ve picked the most suitable service providers for this project.
                Toggle between providers to review your options. Once you have chosen your preferred provider, click “I
                ACCEPT THIS OFFER” to confirm that you are happy to proceed with this service provider and the estimates provided.
            </p>}*/}
            {
                userData?.status.type_code === 100 && tasks.length >= 2 && <p className={'task-tab-list__top-info'}>
                    We have picked out few most suitable service providers for this project. Please review these options. By clicking “ACCEPT” you confirm your approval of this Service Provider and their estimates for this project.
                </p>
            }
            {(userData?.status.type_code === 100 && +accepted === 1) && <p className={'task-tab-list__top-info'}>
                Please review the list of tasks and estimates we have prepared for this project.
                By clicking “ACCEPT” you confirm your approval to start legal works with these estimates.
            </p>}
            {(userData?.status.type_code === 100 && +accepted === 2 && tasks.length === 1) && <p className={'task-tab-list__top-info'}>
                Please review the list of tasks and estimates we have prepared for this project.
                By clicking “ACCEPT” you confirm your approval to start legal works with these estimates.
            </p>}
            {tasks.length > 1 && <div className="tabs__header" style={{margin: '0 0 20px 0'}}>
                {
                    (tasks || []).map((el: any, index: number) => (
                        <span onClick={() => setActiveIndex(index)} key={index}
                              className={`tabs__header-item ${activeIndex === index && 'tabs__header-item-active'}`}>Provider {index + 1}</span>
                    ))
                }
            </div>}
            {tasks.length <= 1 && <div className="tabs__header" style={{margin: '0 0 20px 0'}}>
                <span className={`tabs__header-item tabs__header-item-active`}>Tasks</span>
            </div>}
            {
                userData?.status.type_code === 100 && +accepted === 2 && (tasks || [])[activeIndex]?.provider && <ProviderInfo
                    provider={(tasks || [])[activeIndex]?.provider}
                    setModalType={setModalType}
                    setModalData={setModalData}
                />
            }
            {userData?.status.type_code === 300 && <div
                onClick={() => {
                    setModalData({ project_id: project?.id })
                    setModalType('create_legal_task')
                }}
                className={'task-tab-list__button'}>
                <div className={'task-tab-list__circle'}>
                    <img src={createIcon} alt=""/>
                </div>
                <b>ADD TASK</b>
            </div>}
            {(userData?.status.type_code === 100 && +accepted === 0) && [...providerItems, ...vloItems].length === 0 && <div className={'task-tab-list__no-task'}>
                <h2>NO TASKS YET</h2>
                <p>
                    This offer is being prepared by your VLO. The list of tasks and estimates will become visible to you
                    once the offer is ready.
                </p>
            </div>}
            {tasks.length > 0 && <div className={'task-tab-list__table mt10'}>
                {/*{window.innerWidth > 500 && <tr className={'task-tab-list__tHead'}>
                    <th>Title</th>
                    <th>Provider</th>
                    {showStatus && <th>Status</th>}
                    <th>{accepted === 5 ? 'Deadline' : 'Estimate'}</th>
                    <th>Price</th>
                </tr>}*/}
                {vloItems.length > 0 && <div>
                    <div style={{ color: '#8A93AD', fontSize: '13px', fontWeight: 400 }}>LEGAL NODES TASKS</div>
                    {
                        (vloItems || []).map((item: any) => {
                            return <TaskItem
                                {...item}
                                showStatus={showStatus}
                                project_id={project?.id}
                                deleteTask={deleteTask}
                                isAcceptToDeleteTask={isAcceptToDeleteTask}
                                accepted={accepted}
                                partner_request_id={(tasks || [])[activeIndex]?.partner_request_id}
                                createDeliverable={createDeliverable}
                                deleteDeliverableItem={deleteDeliverableItem}
                                deliverables={deliverables.filter((deliverable: any) => +deliverable.task_id === +item.id)}
                                haveToBeApprove={(data: any) => setHaveToBeApproveModal(data)}
                            />
                        })
                    }
                </div>}

                {providerItems.length > 0 && <div>
                    <div style={{ color: '#8A93AD', fontSize: '13px', fontWeight: 400, marginTop: '20px' }}>PROVIDER TASKS</div>
                    {
                        (providerItems || []).map((item: any) => {
                            return <TaskItem
                                {...item}
                                showStatus={showStatus}
                                project_id={project?.id}
                                deleteTask={deleteTask}
                                isAcceptToDeleteTask={isAcceptToDeleteTask}
                                accepted={accepted}
                                partner_request_id={(tasks || [])[activeIndex]?.partner_request_id}
                                createDeliverable={createDeliverable}
                                deleteDeliverableItem={deleteDeliverableItem}
                                deliverables={deliverables.filter((deliverable: any) => +deliverable.task_id === +item.id)}
                                haveToBeApprove={(data: any) => setHaveToBeApproveModal(data)}
                            />
                        })
                    }
                </div>}

            </div>}
        </div>
    )
}